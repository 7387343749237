import React from "react"
import { Layout, Image, SEO, Grid, GridCol } from "../components";

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Grid>
      <GridCol className={'col-12'}>
        <Image />
        <p>London based Video Director &amp; Visual Artist Judy Jacob is&nbsp;a
          Theatre graduate of Central Saint Martins. &nbsp;Specialising in film
          and video, her portfolio includes both independent and client driven
          projects across the spectrum of visual arts disciplines. &nbsp;</p>

        <p>2013: Queens of the Stone Age 'Like Clockwork' Tour Visuals, Celine
          Dion Strictly Come Dancing and X Factor screen visuals, Leona Lewis X
          factor US 'One More Sleep' Screen content, National Television Awards
          Sam Bailey 'Power of Love' screen visuals, Little Mix Surprise
          Surprise 'These Four Walls' screen visuals, &nbsp;Demi Lovato -
          Britains Got Talent 'Heart Attack' Screen Visuals, &nbsp;Leona Lewis
          'Glassheart' Tour Visuals, Wost of Scottee "Video Content' and 'Eau de
          Toilette' Short Film.</p>

        <p>2012: Mumford and Sons 'Babel' Tour Visuals for LED Spheres, FUN 'We
          Are Young' X Factor Screen Visuals, Diana Krall 'Glad Rag Doll' Tour
          Visuals,&nbsp;<a
            href="http://www.judyjacob.co.uk/blog/stone-roses-tour-visuals">The
            Stone Roses</a> Tour Visuals, Ghostpoet Projection Design for <a
            href="http://www.judyjacob.co.uk/blog/ghostpoet-koko">KOKO</a> and <a
            href="http://www.judyjacob.co.uk/project/ghostpoet">Channel 4 Random
            Acts</a>,&nbsp;<a
            href="http://www.judyjacob.co.uk/project/ilia-darlin">Ilia Darlin
            'Hit Me' Music Video,</a> Dave Packer '<a
            href="http://www.judyjacob.co.uk/blog/shopping-bags-short-film">Shopping
            Bags</a>' Short film, <a
            href="http://www.judyjacob.co.uk/blog/50-years-sunday-times-magazine">50
            Years Anniversary of the Sunday Times Magazine (projection
            design)</a>.</p>

        <p>2011: <a href="http://beggarsvelvet.com/project/black-francis"
                    target="_blank" rel="noopener noreferrer" >Black Francis Short Film</a>,&nbsp;<a
          href="http://beggarsvelvet.com/project/alison-krauss-union-station"
          target="_blank" rel="noopener noreferrer" >Alison Krauss Tour Visuals 'Paper Airplane',</a> <a
          href="http://beggarsvelvet.com/project/james-blunt" target="_blank" rel="noopener noreferrer" >James
          Blunt Tour Visuals 'some Kind of Trouble',</a> <a
          href="http://beggarsvelvet.com/project/blaas-glory" target="_blank" rel="noopener noreferrer" >Blaas
          of Glory 'Enter Venusss' Music Video,</a> <a
          href="http://beggarsvelvet.com/project/sonic-sideshow"
          target="_blank" rel="noopener noreferrer" >Sonic Sideshow 'Soul Suits' Promo,</a> <a
          href="http://beggarsvelvet.com/project/lisa-stansfield-exp"
          target="_blank" rel="noopener noreferrer" >The Lisa Stansfield Experience feat Scottee,</a> <a
          href="http://beggarsvelvet.com/project/my-chemical-romance"
          target="_blank" rel="noopener noreferrer" >My Chemical Romance Tour Visuals,</a> <a
          href="http://beggarsvelvet.com/project/adele" target="_blank" rel="noopener noreferrer" >Adele
          Tour Visuals 21,</a> <a
          href="http://beggarsvelvet.com/project/david-bailey" target="_blank" rel="noopener noreferrer" >In
          Conversation with David Bailey &amp; Tim Marlow for Scream
          Gallery,</a> <a href="http://beggarsvelvet.com/project/laura-marling"
                          target="_blank" rel="noopener noreferrer" >Experiment in Awkwardness with Laura
          Marling.</a></p>

        <p>2010: <a href="http://beggarsvelvet.com/project/peter-gabriel"
                    target="_blank" rel="noopener noreferrer" >Peter Gabriel New Blood Tour Visuals,</a> <a
          href="http://beggarsvelvet.com/project/sissy-and-blisters"
          target="_blank" rel="noopener noreferrer" >Sissy &amp; the Blisters 'Things May Change' Music
          Video &amp; Teaser,</a> <a
          href="http://beggarsvelvet.com/project/scissor-sisters"
          target="_blank" rel="noopener noreferrer" >Scissor Sisters iTunes Festival &amp; Tour
          Visuals,</a> <a href="http://beggarsvelvet.com/project/do-or-dies"
                          target="_blank" rel="noopener noreferrer" >The Do or Dies 'Maps &amp; Plans'
          Music Video,</a> <a
          href="http://beggarsvelvet.com/project/ilia-darlin" target="_blank" rel="noopener noreferrer" >Ilia
          Darlin 'Car Crash' Music Video,</a> <a
          href="http://beggarsvelvet.com/project/she-makes-war" target="_blank" rel="noopener noreferrer" >She
          Makes War 'Scared to Capsize' Music Video,</a><a
          href="http://beggarsvelvet.com/project/fourtet"
          target="_blank" rel="noopener noreferrer" > Fourtet Tour Visuals,</a> <a
          href="http://beggarsvelvet.com/project/snow-patrol" target="_blank" rel="noopener noreferrer" >Snow
          Patrol Tour Visuals,</a> <a
          href="http://beggarsvelvet.com/project/human-league" target="_blank" rel="noopener noreferrer" >The
          Human League Tour Visuals.</a></p>

        <p>2009: <a href="http://beggarsvelvet.com/project/pixies"
                    target="_blank" rel="noopener noreferrer" >Pixies Short Films 'Crackity Jones',
          'Tame' &amp; 'Mr Grieves',</a> <a
          href="http://beggarsvelvet.com/project/sissy-short-film"
          target="_blank" rel="noopener noreferrer" >Sissy - Short Film,</a> <a
          href="http://beggarsvelvet.com/project/pet-shop-boys" target="_blank" rel="noopener noreferrer" >Pet
          Shop Boys Tour Visuals,</a> <a
          href="http://beggarsvelvet.com/project/rain-emperor" target="_blank" rel="noopener noreferrer" >The
          Rain Emperor Performance Event,</a> <a
          href="http://beggarsvelvet.com/project/paul-weller" target="_blank" rel="noopener noreferrer" >Paul
          Weller Visuals O2 Arena,</a><a
          href="http://beggarsvelvet.com/project/peek-show" target="_blank" rel="noopener noreferrer" > The
          Peek Show Theatre Space,</a> <a
          href="http://beggarsvelvet.com/project/keane" target="_blank" rel="noopener noreferrer" >Keane
          Tour Visuals,</a> <a href="http://beggarsvelvet.com/project/coldplay"
                               target="_blank" rel="noopener noreferrer" >Coldplay Viva la Vida Tour
          Visuals,</a> <a
          href="http://beggarsvelvet.com/project/die-toten-hosen"
          target="_blank" rel="noopener noreferrer" >Die Toten Hosen Rock am Ring Visuals,</a> <a
          href="http://beggarsvelvet.com/project/kings-leon" target="_blank" rel="noopener noreferrer" >Kings
          of Leon Tour Visuals 'Because of the Times', </a><a
          href="http://beggarsvelvet.com/project/down-rabbit-hole"
          target="_blank" rel="noopener noreferrer" >Down the Rabbit Hole - Set Design.</a></p>

        <p>2004-2008: <a href="http://beggarsvelvet.com/project/one-night-stand"
                         target="_blank" rel="noopener noreferrer" >One Night Stand Theatre @
          Lovebox,</a> Curator for Ear Horn Monthly Performance Art Event,
          Studio Manager @ Us Creative, Weekly Club Projections for Artrocker @
          FROG Astoria, <a href="http://beggarsvelvet.com/project/kings-leon"
                           target="_blank" rel="noopener noreferrer" >Kings of Leon Tour Visuals 'Aha Shake
            Heartbreak',</a> <a
            href="http://beggarsvelvet.com/project/ghost-shadwell-stair"
            target="_blank" rel="noopener noreferrer" >The Ghost of Shadwell Stair.</a></p>
      </GridCol>
    </Grid>
  </Layout>
)

export default AboutPage
